import React, {useEffect} from "react";
import ProviderItem from "./ChooseProvider/ProviderItem";
import NetworkingProvider from "../../../helpers/NetworkingProvider";
import backButton from "../../../images/booking_back.svg";

const ChooseProvider = (props) => {
    const [providers, setProviders] = React.useState([1,2,3,4,5,6,7,8,9,10]);
    const { currentStep, selectedService, selectedProvider, currentStepIndex, providerSelected, goBack } = props;

    async function fetchAllServiceProviders() {
        if (!selectedService) {
            return console.log('No service selected');
        }
        const payload = { serviceId: selectedService}
        try {
            const dataResponse = await NetworkingProvider.fetchDataFullResponse('services/list-providers', 'POST', payload);
            const result = dataResponse.result;
            console.log(result)
            if (result) {
                setProviders(result)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function handleProviderSelected(e) {
        providerSelected(e)
    }

    function handleBack(e) {
        e.preventDefault();
        goBack()
    }

    useEffect(() => {
        fetchAllServiceProviders()
    },[selectedService])

    return (
        <div data-w-tab="choose-provider" className={`choose-provider w-tab-pane ${currentStep === currentStepIndex ? 'w--tab-active' : ''}`}>
            <div className="booking-inner-holder">
                <div className="div-block-51">
                    <a href="/" onClick={(e) => handleBack(e)} className="booking-back w-inline-block">
                        <img src={backButton} loading="lazy" width="23.5" alt="" className="image-3"/>
                    </a>
                    <div className="booking-title-label-back">Choose a Professional</div>
                </div>
                <div className="choose-provider-holder">
                    <div className="w-layout-grid choose-provider-grid">
                        <div className="individual-provider-holder">
                            <div className="inner-provider-holder">
                                <div className="randon-provider-icon"></div>
                                <div className="select-provider-title-label">Any Available
                                    Professional
                                </div>
                                <div className="select-provider-desc-label">You will
                                    select <br/>services fast
                                </div>
                            </div>
                        </div>
                        {providers.map((provider, index) => (
                            <div style={{cursor: "pointer"}} key={index}
                                 onClick={() => handleProviderSelected(provider)}>
                                <ProviderItem provider={provider} selected={selectedProvider === provider}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseProvider
