import React, {useEffect, useRef} from "react";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {Stack} from "@mui/material";
import DateItem from "./DateItem";
import TimeslotItem from "./TimeslotItem";
import TimeslotMonthItem from "./TimeslotMonthItem";
// import Stack from '@mui/material/Stack';

const DateSelector = (props) => {

    const { dateSelected } = props;

    const [count, setCount] = React.useState(0);
    const [initialized, setInitialized] = React.useState(false);
    const monthStrings = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [months, setMonths] = React.useState([0,1,2,3,4,5,6,7,8,9,10,11]);
    const [selectedMonth, setSelectedMonth] = React.useState((new Date()).getMonth());
    const [days, setDays] = React.useState([]);
    const [selectedDay, setSelectedDay] = React.useState((new Date()).getDate());
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const [currentSorting, setCurrentSorting] = React.useState('This Week');

    const [monthSelectorOpen, setMonthSelectorOpen] = React.useState(false);

    const divRefs = useRef(null);

    const scrollToIndex = () => {
        let children = divRefs.current.children ?? [];
        if (children.length > 0) {
            divRefs.current.children[selectedDay].scrollIntoView({ behavior: 'smooth' });
        }
    };

    function handleSortingChange(e) {
        if (currentSorting === 'This Week') {
            setCurrentSorting('Next Week')
        } else {
            setCurrentSorting('This Week')
        }
    }

    function handleClickDatepickerIcon() {
        setMonthSelectorOpen(!monthSelectorOpen)
    }

    function handleMonthSelection(e, initial) {
        setSelectedMonth(e)
        setMonthSelectorOpen(false)
        const year = new Date().getFullYear(); // current year
        const daysArray = getDaysInMonth(e, year);
        setDays(daysArray)
        setSelectedDay(daysArray[0]?.day ?? 0)

        if (initial === true) {
            const createdDate = createDateFromMonthDay(selectedMonth, selectedDay);
            handleSelectedDate(createdDate)
        } else {
            const createdDate = createDateFromMonthDay(e, daysArray[0]?.day ?? 0);
            // console.log(createdDate)
            handleSelectedDate(createdDate)
        }


    }

    function handleDaySelection(e) {
        setSelectedDay(e)
        const createdDate = createDateFromMonthDay(selectedMonth, e);
        // console.log(createdDate)
        handleSelectedDate(createdDate)
    }

    function handleSelectedDate(e) {
        setSelectedDate(e);
        dateSelected(e)
        setCount(count + 1)
    }

    const createDateFromMonthDay = (month, day) => {
        const year = new Date().getFullYear();
        return new Date(year, month, day); // months are 0-indexed in JS Date
    };

    const getDaysInMonth = (month, year) => {
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const daysArray = [];
        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(year, month, day);
            daysArray.push({
                day: day,
                weekday: date.getDay(),
            });
        }
        return daysArray;
    };

    useEffect(() => {
        if (!initialized) {
            const year = new Date().getFullYear(); // current year
            const daysArray = getDaysInMonth(selectedMonth, year);
            setDays(daysArray)
            setInitialized(true)
        } else {
            scrollToIndex()
        }
    },[initialized])

    useEffect(() => {
        // handleMonthSelection(new Date().getMonth(), true)
        scrollToIndex()
    }, [count]);

    return (
        <>
            <div className="div-block-32">
                <div className="div-block-33">
                    <div>
                        <div onClick={() => handleClickDatepickerIcon()} className="text-block-18">{monthStrings[selectedMonth]} 2024</div>
                    </div>
                    <div className="div-block-34" onClick={() => handleSortingChange()} style={{cursor: 'pointer'}}>
                        <div className="text-block-19">{currentSorting}</div>
                    </div>
                </div>
                {monthSelectorOpen ? (
                    <div className="div-block-37" style={{ marginBottom: '30px'}}>
                        <div className="w-layout-grid grid">
                            {months.map((month, index) => (
                                <div key={index} style={{cursor: "pointer"}}>
                                    <TimeslotMonthItem month={month} selected={selectedMonth === month} selectedMonth={(e) => handleMonthSelection(e)}/>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}
                <div className="div-block-36" ref={divRefs}>
                    {days.map((item, index) => (
                        <div onClick={() => handleDaySelection(item?.day)}>
                            <DateItem day={item?.day} weekday={item?.weekday} selected={selectedDay === item?.day} selectedDay={(e) => handleDaySelection(item?.day)}/>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
};

export default DateSelector;
