import React from "react";

const ProviderItem = (props) => {
    const { provider, selected, description } = props;

    return (
        <div className={selected ? 'individual-provider-holder-selected' : `individual-provider-holder`} style={{ cursor: "pointer"}}>
            <div className="inner-provider-holder" style={{ cursor: "pointer"}}>
                <div className="choose-provider-profile-image">
                    <img style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '13px'}} src={provider?.photo} alt="" />
                </div>
                <div className={selected ? 'select-provider-title-label-selected' : `select-provider-title-label`}>{provider?.name}
                </div>
                <div className={selected ? 'select-provider-desc-label-selected' : `select-provider-desc-label`}>Multiple Upcoming Availabilities
                </div>
            </div>
        </div>
    )
}

export default ProviderItem;
