import React, {useEffect, useState} from 'react';
import '../styles/webflow.css';
import '../styles/parlordore-client.webflow.css';
import '../styles/normalize.css';
import SelectService from "./scheduling/Steps/SelectService";
import ChooseProvider from "./scheduling/Steps/ChooseProvider";
import ChooseAddons from "./scheduling/Steps/ChooseAddons";
import ChooseTimeslot from "./scheduling/Steps/ChooseTimeslot";
import Confirmation from "./scheduling/Steps/Confirmation";
import Scheduler from "./scheduling/Scheduler";

const HeroSection = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleServicesClicked(e) {
        e.preventDefault();
    }

    useEffect(() => {
        //
    },[windowWidth])
    return (
        <section className="hero">
            <div className="w-layout-blockcontainer container-2 w-container">
                <div className="hero-columns w-row">
                    <div className="hero-left-column w-col w-col-8 w-col-stack">
                        <div className="hero-left-holder">
                            <h1 className="main-heading">Charlotte's #1 Leading <span className="text-span">Tattoo Parlor</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={windowWidth > 825 ? "81": "45"}
                                    height={windowWidth > 825 ? "81": "45"}
                                    fill="currentColor"
                                    viewBox="0 0 81 81"
                                    style={{marginLeft: '8px'}}
                                >
                                    <path
                                        d="M81 40.5C81 62.8675 62.8675 81 40.5 81C18.1325 81 0 62.8675 0 40.5C0 18.1325 18.1325 0 40.5 0C62.8675 0 81 18.1325 81 40.5Z"
                                        fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M51.5 28.5C52.8807 28.5 54 29.6193 54 31V56H28C26.6193 56 25.5 54.8807 25.5 53.5C25.5 52.1193 26.6193 51 28 51H44.6054L27.6464 34.0411C26.6701 33.0647 26.6701 31.4818 27.6464 30.5055C28.6228 29.5292 30.2057 29.5292 31.182 30.5055L49 48.3235V31C49 29.6193 50.1193 28.5 51.5 28.5Z"
                                          fill="#84B963"/>
                                </svg>
                            </h1>
                            <div className="main-paragraph">Discover Our Unique Style: Elevating grooming standards with
                                innovative techniques and personalized service. Visit us and experience the difference.
                            </div>
                            <a href="/" onClick={(e) => handleServicesClicked(e)} className="check-services-link">Check
                                our services</a>
                        </div>
                    </div>
                    <div className="hero-right-column w-col w-col-4 w-col-stack">
                    <Scheduler />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
