import React from "react";

const ServiceItem = (props) => {
    const { service } = props;

    function stripeAmountToLocale() {
        const cost = service?.service?.price ?? 0;
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatter.format(cost / 100);
    }

    return (
        <div className="individual-service-holder" style={ service?.service?.thumbnail_image_url ? {backgroundImage: `url(${service?.service?.thumbnail_image_url})`} : {}}>
            <div className="inner-service-holder" >
                <div className="select-service-type-holder">
                    <div className="select-service-type-label">Salon</div>
                </div>
                <div className="individual-service-bottom-holder">
                    <div className="select-service-price-holder">
                        <div className="select-service-price-label">{stripeAmountToLocale()}
                        </div>
                    </div>
                    <div className="select-service-title-label">{service?.service?.title}
                    </div>
                    {/*<div className="select-service-provider-label">{service?.providers?.length} Providers*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );

};

export default ServiceItem;
