import React, {useEffect} from "react";

const AddonItem = (props) => {
    const { addon, selected } = props;

    function stripeAmountToLocale() {
        const cost = addon?.price ?? 0;
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatter.format(cost / 100);
    }

    return (
        <div className={selected() ? `addon_card_selected` : `addon_card`}>
            <div className="item_card_top">
                <div className={selected() ? `text-block-15-selected` : `text-block-15`}>{addon?.title}</div>
                <div className={selected() ? `text-block-16-selected` : `text-block-16`}>45min</div>
            </div>
            <div className="div-block-28">
                <div
                    id="w-node-_0ff2f367-efcd-a720-53bb-0085ce96fb9c-03cc0abe"
                    className="div-block-29">
                    <div className="text-block-17">+{stripeAmountToLocale()}</div>
                </div>
            </div>
        </div>
    );
};

export default AddonItem;
