import logo from './logo.svg';
import './App.css';
import './styles/webflow.css';
import HeroSection from "./components/HeroSection";
import NotFoundPage from "./components/NotFound";
import ServicesSection from "./components/ServicesSection";
import FooterSection from "./components/FooterSection";
import {useEffect, useState} from "react";
import Navbar from "./components/Navbar";
import NetworkingProvider from "./helpers/NetworkingProvider";
import ParlorHelper from "./helpers/ParlorHelper";
import {Helmet} from "react-helmet";

function App() {
    const [isVisible, setIsVisible] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [parlorFound, setParlorFound] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getSubdomain = (url) => {
        const parsedUrl = new URL(url);
        const hostname = parsedUrl.hostname;
        if (hostname.includes('.localhost')) {
            const parts = hostname.split('.');
            // Check if there are at least 2 parts (subdomain, domain)
            if (parts.length > 1) {
                return parts[0];
            }
            return null;
        } else {
            const parts = hostname.split('.');
            // Check if there are at least 2 parts (subdomain, domain)
            if (parts.length > 2) {
                return parts[0];
            }
            return null;
        }
    };

    async function initializeParlor() {
        let subdomain = getSubdomain(window.location.href);
        if (subdomain === 'www' || subdomain === null) {
            return window.location.href = 'https://www.parlordore.com'
        }
        try {
            const payload = { 'slug': subdomain}
            const dataResponse = await NetworkingProvider.fetchData('parlor/validate', 'POST', payload);
            const result = dataResponse.result;
            if (result) {
                console.log(result)
                ParlorHelper.storeParlor(result);
                setParlorFound(true)
                setInitialized(true)
            }
        } catch (error) {
            console.log(error)
            setParlorFound(false)
            setInitialized(true)
        }

    }

    useEffect(() => {
        initializeParlor()
    },[initialized])

    return (
        <div className="App">
            {initialized ? (
                <>
                    {parlorFound ? (
                        <>
                            <Helmet>
                                <title>{ParlorHelper.getParlor()?.name || 'Parlordor'}</title>
                                <meta name={'description'} content={ParlorHelper.getParlor()?.description || ''}/>
                                <meta property="description" content={ParlorHelper.getParlor()?.description || ''}/>

                                <meta property="og:title" content={ParlorHelper.getParlor()?.name || 'Parlordor'}/>
                                <meta property="og:description" content={ParlorHelper.getParlor()?.description || ''}/>

                                <meta name="twitter:creator" content={'Parlordore'}/>
                                <meta name="twitter:title" content={ParlorHelper.getParlor()?.name || 'Parlordor'}/>
                                <meta name="twitter:description" content={ParlorHelper.getParlor()?.description || ''}/>
                            </Helmet>
                            <Navbar pinned={isVisible}/>
                            <HeroSection/>
                            <ServicesSection/>
                            <FooterSection/>
                        </>
                    ): (
                        <>
                            <Helmet>
                                <title>{'Parlordor | Parlor Not Found'}</title>
                                <meta name={'description'} content={'Parlor Not Found'}/>
                                <meta property="description" content={'Parlor Not Found'}/>

                                <meta property="og:title" content={'Parlordor | Parlor Not Found'}/>
                                <meta property="og:description" content={'Parlor Not Found'}/>

                                <meta name="twitter:creator" content={'Parlordore'}/>
                                <meta name="twitter:title" content={'Parlordor | Parlor Not Found'}/>
                                <meta name="twitter:description" content={'Parlor Not Found'}/>
                            </Helmet>
                            <NotFoundPage />
                        </>
                        ) }
                </>
            ) : null}
        </div>
    );
}

export default App;
