import React from 'react';
import '../styles/webflow.css';
import '../styles/parlordore-client.webflow.css';
import '../styles/normalize.css';

const FooterSection = () => {
    return (
        <section className="footer">
            <div className="w-layout-blockcontainer container-4 w-container">
                <div className="footer-holder">
                    <div className="footer-logo"></div>
                    <div className="get-notified-label">Get notified about all of our latest<br/>promotions and discounts</div>
                    <div className="form-block w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form">
                            <input className="text-field w-input" maxLength="256" name="email" data-name="Email" placeholder="Enter your email" type="email" id="email" required/>
                            <input type="submit" data-wait="Please wait..." className="submit-button w-button" value="Submit"/>
                        </form>
                        <div className="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                    <div className="nav-items-holder">
                        <a href="#" className="main-nav-link">Home</a>
                        <a href="#" className="main-nav-link">Services</a>
                        <a href="#" className="main-nav-link">Privacy Policy</a>
                        <a href="#" className="main-nav-link">Contact</a>
                        <a href="#" className="main-nav-link">Cookies</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FooterSection;
