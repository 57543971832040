import React, {useEffect} from "react";

const SchedulerCostView = (props) => {
    const { handleNext, currentIndex = 0, selectedService, selectedAddons, selectedProvider } = props;
    const [amount, setAmount] = React.useState(null);

    function next(e) {
        e.preventDefault();
        handleNext();
    }

    function buttonTitle() {
        let buttonTitle = '';
        if (currentIndex === 0) {
            buttonTitle = 'Select Addons';
        } else if (currentIndex === 1) {
            buttonTitle = 'Choose a Service Provider';
        } else if (currentIndex === 2) {
            buttonTitle = 'Select Timeslot';
        } else {
            buttonTitle = 'Confirm Booking';
        }
        return buttonTitle;
    }

    function calculateCosts() {
        const serviceCost = selectedService?.service.price ?? 0;
        const addonCosts = (selectedAddons ?? []).reduce((total, item) => total + (item?.price || 0), 0);
        const totalCost = serviceCost + addonCosts;

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        const costString = formatter.format(totalCost / 100);
        setAmount(costString)
    }

    useEffect(() => {
        calculateCosts()
    },[]);

    return (
        <div className="booking-info-holder">
            <div className="booking-info-title-label">Your Booking</div>
            {selectedService !== undefined && (
                <div className="appointment-confirmation-entry-holder">
                    <div className="appointment-confirmation-entry-title">Selected Service
                    </div>
                    <div className="appointment-confirmation-entry-value-label">{selectedService?.service?.title}
                    </div>
                </div>
            )}
            {selectedProvider && (
                <div className="booking-info-inner-holder">
                    <div className="booking-info-inner-left">
                        <div className="booking-info-provider-image">
                            <img style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '13px'}}
                                 src={selectedProvider?.photo} alt=""/>
                        </div>
                        <div className="div-block-25">
                            <div className="booking-info-provider-label">{selectedProvider?.name}
                            </div>
                            <div className="booking-info-provider-description-label">{selectedProvider?.bio ?? 'Service Provider'}
                            </div>
                        </div>
                    </div>
                    <div className="booking-info-price-label">{amount}</div>
                </div>
            )}

            <a href="/" onClick={(e) => next(e)} className="button w-button">{buttonTitle()}</a>
        </div>
    )
}

export default SchedulerCostView;
