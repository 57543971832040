import React from "react";
import '../styles/webflow.css';
import '../styles/parlordore-client.webflow.css';
import '../styles/normalize.css';

const Navbar = (props) => {
    const { pinned } = props;

    return (
        <div className={pinned ? 'main-nav-bar-scrolled' : "main-nav-bar"}>
            <div className="w-layout-blockcontainer container w-container">
                <div className="nav-holder">
                    <a href="/" className="nav-logo w-inline-block"></a>
                    <div className="div-block-30">
                        <a href="/" className="main-nav-link">Services</a>
                        <a href="/" className="main-nav-link">Privacy Policy</a>
                        <a href="/" className="main-nav-link">Contact</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Navbar;
