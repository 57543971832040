import React from "react";

const DateItem = (props) => {
    const { weekday, day = 0, year, selected, next, prev } = props;

    const dow = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return (
        <div className="choose-time-date-holder">
            <div className={selected ? 'choose-time-day-holder-selected' : `choose-time-day-holder`}>
                <div className={selected ? 'choose-time-day-label-selected' : `choose-time-day-label`}>{day}</div>
            </div>
            <div className={selected ? 'dow-label-selected' : `dow-label`}>{!next && !prev ? dow[weekday ?? 0] : next ? 'Prev' : prev ? 'Next' : ''}</div>
        </div>
    )
};

export default DateItem;
