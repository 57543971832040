import SelectService from "./Steps/SelectService";
import ChooseAddons from "./Steps/ChooseAddons";
import ChooseProvider from "./Steps/ChooseProvider";
import ChooseTimeslot from "./Steps/ChooseTimeslot";
import Confirmation from "./Steps/Confirmation";
import React, {useEffect} from "react";
import SchedulerCostView from "./Components/SchedulerCostView";

const Scheduler = () => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const [selectedService, setSelectedService] = React.useState(undefined);
    const [selectedServiceObject, setSelectedServiceObject] = React.useState(undefined);
    const [selectedProvider, setSelectedProvider] = React.useState(null);
    const [selectedTimeslot, setSelectedTimeslot] = React.useState(null);
    const [selectedTimeslotDate, setSelectedTimeslotDate] = React.useState(null);
    const [selectedAddons, setSelectedAddons] = React.useState([]);

    const [count, setCount] = React.useState(0);

    function handleServiceSelected(e) {
        if (selectedService === e.service?._id) {
            setSelectedService(undefined)
            setSelectedServiceObject(undefined)
        } else {
            setSelectedService(e.service?._id)
            setSelectedServiceObject(e)
        }

        console.log('Service:', selectedService)
        setCount(count + 1)
    }

    function handleAddonsChosen(e) {
        setSelectedAddons(e)
        console.log('Addons:', selectedAddons)
        setCount(count + 1)
    }

    function handleProviderSelected(e) {
        setSelectedProvider(e)
        console.log('Provider:', selectedProvider)
        setCount(count + 1)
    }

    function handleTimeslotSelected(e, date) {
        setSelectedTimeslot(e)
        setSelectedTimeslotDate(date)
        console.log('Timeslot:', selectedTimeslot)
        console.log('Timeslot Date:', date)
        setCount(count + 1)
    }

    function handleNext() {
        if (currentStep < 4) {
            setCurrentStep(currentStep + 1)
        }
    }

    function handleBack() {
        setCurrentStep(currentStep - 1)
    }

    useEffect(() => {

    },[count])

    return (<div className="hero-booking-holder">
        <div data-current="select-service" data-easing="ease" data-duration-in="300"
             data-duration-out="100" className="tabs w-tabs">
            <div className="tabs-menu w-tab-menu">
                <a data-w-tab="select-service" className="w-inline-block w-tab-link w--current">
                    <div>Tab 1</div>
                </a>
                <a data-w-tab="choose-provider" className="w-inline-block w-tab-link ">
                    <div>Tab 4</div>
                </a>
                <a data-w-tab="choose-addon" className="w-inline-block w-tab-link">
                    <div>Tab 2</div>
                </a>
                <a data-w-tab="choose-timeslot" className="w-inline-block w-tab-link">
                    <div>Tab 3</div>
                </a>
                <a data-w-tab="confirmation" className="w-inline-block w-tab-link ">
                    <div>Tab</div>
                </a>
            </div>
            <div className="tabs-content w-tab-content">
                <SelectService currentStep={currentStep} currentStepIndex={0} selectedService={selectedService} serviceSelected={(e) => handleServiceSelected(e)}/>
                <ChooseAddons currentStep={currentStep} currentStepIndex={1} selectedService={selectedService} selectedAddons={selectedAddons} addonsSelected={(e) => handleAddonsChosen(e)} goBack={() => handleBack()}/>
                <ChooseProvider currentStep={currentStep} currentStepIndex={2} selectedService={selectedService} selectedProvider={selectedProvider} providerSelected={(e) => handleProviderSelected(e)} goBack={() => handleBack()}/>
                <ChooseTimeslot currentStep={currentStep} currentStepIndex={3} selectedService={selectedService} selectedProvider={selectedProvider} selectedTimeslot={selectedTimeslot} timeslotSelected={(e, date) => handleTimeslotSelected(e, date)} goBack={() => handleBack()}/>
                <Confirmation currentStep={currentStep} currentStepIndex={4} selectedService={selectedServiceObject} selectedProvider={selectedProvider} selectedAddons={selectedAddons} selectedTimeslot={selectedTimeslot} selectedTimeslotDate={selectedTimeslotDate}/>
            </div>
            {currentStep !== 4 && (selectedService !== undefined) && (
                <SchedulerCostView selectedService={selectedServiceObject} selectedProvider={selectedProvider} selectedAddons={selectedAddons} selectedTimeslot={selectedTimeslot} currentIndex={currentStep} handleNext={handleNext}/>
            )}
        </div>

    </div>);

}

export default Scheduler;
