import React from "react";

const TimeslotItem = (props) => {
    const { timeslot, selected } = props;

    return (
        <div className={selected ? 'timeslot-holder-selected' : `timeslot-holder`}>
            <div
                id="w-node-_265b52ee-b158-e67b-147f-fcf5aa79c3c2-03cc0abe"
                className="timeslot-icon"></div>
            <div
                id="w-node-_82e8519e-e229-0ce8-de64-e02ec633aca1-03cc0abe"
                className="timeslot-time-label">{timeslot?.start_time}
            </div>
        </div>
    )
}

export default TimeslotItem;
