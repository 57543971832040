import React from "react";

const TimeslotMonthItem = (props) => {
    const { month = 0, selected, selectedMonth } = props;

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    function handleMonthSelection() {
        selectedMonth(month)
    }

    return (
        <div onClick={() => handleMonthSelection()} className={selected ? 'timeslot-month-holder-selected' : `timeslot-month-holder`}>
            <div
                id="w-node-_82e8519e-e229-0ce8-de64-e02ec633aca1-03cc0abe"
                className={selected ? 'timeslot-time-label-selected' : `timeslot-time-label`}>{months[month]}
            </div>
        </div>
    )
}

export default TimeslotMonthItem;
