import React, {useEffect, useState} from 'react';
import '../styles/webflow.css';
import '../styles/parlordore-client.webflow.css';
import '../styles/normalize.css';

const NotFoundPage = () => {
    return (
        <div className="utility-page-wrap">
            <div className="utility-page-content">
                <img
                    src="https://d3e54v103j8qbb.cloudfront.net/static/page-not-found.211a85e40c.svg"
                    alt="Page Not Found"
                />
                <h2>Page Not Found</h2>
                <div>The page you are looking for doesn&#x27;t exist or has been moved</div>
            </div>
        </div>
    );
};

export default NotFoundPage;
