import React, {useEffect} from "react";
import AddonItem from "./ChooseAddon/AddonItem";
import SchedulerCostView from "../Components/SchedulerCostView";
import NetworkingProvider from "../../../helpers/NetworkingProvider";
import backButton from "../../../images/booking_back.svg";

const ChooseAddons = (props) => {
    const { currentStep, currentStepIndex, selectedService, selectedAddons = [], addonsSelected, goBack } = props;

    const [addons, setAddons] = React.useState([1,2,3,4,5,6,7,8,9,10]);
    // const [selectedAddons, setSelectedAddons] = React.useState([]);
    const [count, setCount] = React.useState(0);

    function handleAddonSelected(e) {
        let parsedAddons = selectedAddons;
        if (parsedAddons.includes(e)) {
            parsedAddons = parsedAddons.filter((addon) => addon !== e)
        } else {
            parsedAddons.push(e)
        }
        addonsSelected(parsedAddons)
        setCount(count + 1)
    }

    function isAddonSelected(addon) {
        return selectedAddons.includes(addon);
    }

    async function fetchAllServiceAddons() {
        if (!selectedService) {
            return console.log('No service selected');
        }
        const payload = { serviceId: selectedService}
        try {
            const dataResponse = await NetworkingProvider.fetchDataFullResponse('services/list-addons', 'POST', payload);
            const result = dataResponse.result;
            if (result) {
                setAddons(result)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function handleBack(e) {
        e.preventDefault();
        goBack();
    }

    useEffect(() => {
        fetchAllServiceAddons()
    },[selectedService])

    useEffect(() => {
        //
    }, [count]);

    return (
        <div data-w-tab="choose-addon" className={`choose-addon w-tab-pane ${currentStep === currentStepIndex ? 'w--tab-active' : ''}`}>
            <div className="booking-inner-holder">
                {/*<div className="booking-title-label">Choose an addon</div>*/}
                <div className="div-block-51">
                    <a href="/" onClick={(e) => handleBack(e)}  className="booking-back w-inline-block">
                        <img src={backButton} loading="lazy" width="23.5" alt="" className="image-3"/>
                    </a>
                    <div className="booking-title-label-back">Choose addons</div>
                </div>
                <div className="choose-addon-background">
                    <div className="w-layout-grid choose-addon-grid" style={{marginBottom: '180px'}}>
                        {addons.map((addon, index) => (
                            <div key={index} onClick={() => handleAddonSelected(addon)}>
                                <AddonItem addon={addon} selected={() => isAddonSelected(addon)}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/*<SchedulerCostView handleNext={() => handleContinue()} />*/}
        </div>
    );

}

export default ChooseAddons;
