import React, {useEffect, useState} from 'react';
import '../styles/webflow.css';
import '../styles/parlordore-client.webflow.css';
import '../styles/normalize.css';
import ServiceItem from "./services/ServiceItem";
import NetworkingProvider from "../helpers/NetworkingProvider";
import ParlorHelper from "../helpers/ParlorHelper";

const ServicesSection = () => {

    const [services, setServices] = useState([]);
    async function fetchAllServices() {
        const payload = { parlorId: ParlorHelper.getParlor()?._id}
        try {
            const dataResponse = await NetworkingProvider.fetchDataFullResponse('services/list-all', 'POST', payload);
            const result = dataResponse.result;
            if (result) {
                console.log(result)
                setServices(result)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchAllServices()
    },[])

    return (
        <>
            <section className="services-title">
                <div className="w-layout-blockcontainer width-container w-container">
                    <div className="services-title-colums w-row">
                        <div className="services-title-column-left w-col w-col-6 w-col-stack">
                            <h3 className="secondary-heading">Our Services</h3>
                            <div className="main-paragraph">Discover Our Unique Style: Elevating grooming standards with
                                innovative techniques
                            </div>
                        </div>
                        <div className="services-title-column-right w-col w-col-6 w-col-stack">
                            <div className="inner-services-column-holder">
                                <a href="#" className="our-services-book-now-button w-inline-block">
                                    <div className="text-block-6">BOOK NOW</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="div-block-11"></div>
                </div>
            </section>
            <section className="services">
                <div className="services-holder">
                    <div className="w-layout-grid services-grid">
                        {services.map((service, index) => (
                            <ServiceItem key={index} service={service}/>
                        ))}
                    </div>
                </div>
            </section>
        </>

    )
        ;
};

export default ServicesSection;
