import {baseURL} from "./paths";
export const APIBaseURL = baseURL

const NetworkingProvider = {

    async fetchData(url, method, requestBody, file) {

        let formattedURL = APIBaseURL + url

        try {

            const options = {
                method: method,
                headers: {
                    'origin': '*',
                    'x-requested-with': '*',
                    'Content-Type': 'application/json'
                }
            };

            if (file) {
                // const formData = new FormData();
                // formData.append('file', file);
                // options.body = formData
            }

            if (requestBody) {
                options.body = JSON.stringify(requestBody);
            }

            const response = await fetch(formattedURL, options);

            if (response.ok) {
                return await response.json();
            } else {
                console.log('Failed to fetch data:', response.statusText);

            }
        } catch (error) {
            console.log('Error fetching data:', error);

        }
    },

    async fetchDataFullResponse(url, method, requestBody, file) {

        let formattedURL = APIBaseURL + url

        try {

            const options = {
                method: method,
                headers: {
                    'origin': '*',
                    'x-requested-with': '*',
                    'Content-Type': 'application/json'
                }
            };

            if (file) {
                // const formData = new FormData();
                // formData.append('file', file);
                // options.body = formData
            }

            if (requestBody) {
                options.body = JSON.stringify(requestBody);
            }

            const response = await fetch(formattedURL, options)

            const responseJSON = await response.json();
            if (responseJSON) {
                return responseJSON;
            }

        } catch (error) {
            console.log('Error fetching data:', error);
            return error
        }
    }

};

export default NetworkingProvider;
