import React from "react";
import { useInView } from 'react-intersection-observer';

const ServiceItem = (props) => {
    const { service, currentKey } = props;

    const { ref, inView } = useInView({
        triggerOnce: true, // Load image only once
        threshold: 0.1, // Load image when 10% of the cell is in view
    });

    function stripeAmountToLocale() {
        const cost = service?.service?.price ?? 0;
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatter.format(cost / 100);
    }

    return (
        <div ref={ref} key={service?.service?._id} className="service_item" style={ service?.service?.thumbnail_image_url ? {backgroundImage: inView ? `url(${service?.service?.thumbnail_image_url})` : 'none'} : {}}>
            {/*<img src={service?.service?.thumbnail_image_url} style={{ 'position': 'absolute', height: '100%', width: '100%' }} alt="" />*/}
            <div className="service-item-holder">
                <div className="service-item-category-holder">
                    <div className="service-item-category">
                        <div className="service-item-category-label">Haircut</div>
                    </div>
                </div>
                <div className="service-item-bottom-holder">
                    <div className="service-item-price-holder">
                        <div className="service-item-price">{stripeAmountToLocale()}</div>
                    </div>
                    <div className="service-item-title-label">{service?.service?.title}</div>
                    <div className="service-item-provider-label">By Robert Fox</div>
                </div>
            </div>
        </div>
    )
}

export default ServiceItem;
