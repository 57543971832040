import React, {useEffect, useState} from "react";
import DateSelector from "./ChooseTimeslot/DateSelector";
import TimeslotItem from "./ChooseTimeslot/TimeslotItem";
import SchedulerCostView from "../Components/SchedulerCostView";
import NetworkingProvider from "../../../helpers/NetworkingProvider";
import backButton from "./../../../images/booking_back.svg";

const ChooseTimeslot = (props) => {
    const { currentStep, currentStepIndex, selectedService, selectedProvider, selectedTimeslot, timeslotSelected, goBack } = props;

    // const [selectedTimeslot, setSelectedTimeslot] = useState(null);
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const [timeslots, setTimeslots] = React.useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]);

    function getSelectedDate() {
        const options = { weekday: 'long', month: 'long', day: 'numeric' };
        return selectedDate.toLocaleDateString('en-US', options);
    }

    function handleDateSelection(e) {
        setSelectedDate(e)
    }

    function handleTimeslotSelected(e) {
        e.preventDefault();
        timeslotSelected(e)
    }

    async function fetchAllServiceTimeslots() {
        if (!selectedService || !selectedProvider) {
            return console.log('No service or provider selected');
        }
        const payload = {
            serviceId: selectedService,
            providerId: selectedProvider?._id,
            selectedDate: selectedDate
        }
        try {
            const dataResponse = await NetworkingProvider.fetchDataFullResponse('timeslots/list-all', 'POST', payload);
            const result = dataResponse.result;
            if (result) {
                setTimeslots(result)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function handleBack(e) {
        e.preventDefault();
        goBack()
    }

    const setTimeOnDate = (date, timeString) => {
        const [time, period] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    };

    function handleNewTimeslotSelected(e) {
        const newDate = setTimeOnDate(selectedDate, e.start_time)
        timeslotSelected(e, newDate)
        // console.log(newDate)
    }

    useEffect(() => {
        fetchAllServiceTimeslots()
    },[selectedDate])

    return (
        <div data-w-tab="choose-timeslot" className={`choose-timeslot w-tab-pane ${currentStep === currentStepIndex ? 'w--tab-active' : ''}`}>
            <div className="booking-inner-holder">
                {/*<div className="booking-title-label">Choose a time</div>*/}
                <div className="div-block-51">
                    <a href="/" onClick={(e) => handleBack(e)}  className="booking-back w-inline-block">
                        <img src={backButton} loading="lazy" width="23.5" alt="" className="image-3"/>
                    </a>
                    <div className="booking-title-label-back">Choose a time</div>
                </div>
                <div className="choose-time-background">
                    <DateSelector dateSelected={handleDateSelection}/>
                    <div className="div-block-37">
                        <div className="div-block-33">
                            <div className="text-block-18">{getSelectedDate()}</div>
                        </div>
                        <div className="w-layout-grid grid" style={{marginBottom: '250px'}}>
                            {timeslots.map((timeslot, index) => (
                                <div key={index} style={{cursor: "pointer"}} onClick={() => handleNewTimeslotSelected(timeslot)}>
                                    <TimeslotItem timeslot={timeslot} selected={selectedTimeslot === timeslot}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseTimeslot
