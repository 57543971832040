import React, {useEffect, useState} from "react";
import appleLogo from "../../../images/apple-logo.svg";
import googleLogo from "../../../images/google-logo.svg";
import twitterLogo from "../../../images/twitter-icon.svg";
import mapLogo from "../../../images/appointment_booking_map.png"; //../images/appointment_booking_map.png

const Confirmation = (props) => {
    const { currentStep, currentStepIndex, selectedProvider, selectedService, selectedAddons, selectedTimeslot, selectedTimeslotDate } = props;

    const [amount, setAmount] = useState('$0.00');
    const [addonsAmount, setAddonsAmount] = useState('$0.00');
    const [serviceAmount, setServiceAmount] = useState('$0.00');

    function formatDate() {
        const options = {
            weekday: 'short', // Short weekday name (e.g., 'Tue')
            month: 'short',   // Short month name (e.g., 'Apr')
            day: 'numeric',   // Numeric day of the month (e.g., '10')
            hour: 'numeric',  // Numeric hour (e.g., '8')
            minute: 'numeric', // Numeric minute (e.g., '00')
            hour12: true      // Use 12-hour clock (true for 'AM/PM')
        };

        return (selectedTimeslotDate ?? new Date()).toLocaleString('en-US', options);
    }

    function calculateCosts() {
        const serviceCost = selectedService?.service.price ?? 0;
        const addonCosts = (selectedAddons ?? []).reduce((total, item) => total + (item?.price || 0), 0);
        const totalCost = serviceCost + addonCosts;

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        const serviceCostString = formatter.format(serviceCost / 100);
        const costString = formatter.format(totalCost / 100);
        const addonsCostString = formatter.format(addonCosts / 100);

        setServiceAmount(serviceCostString)
        setAmount(costString)
        setAddonsAmount(addonsCostString)
    }

    function handleGetDirections(e) {
        e.preventDefault();
    }

    function handlePayNow(e) {
        e.preventDefault();
    }

    useEffect(() => {
        calculateCosts()
    },[]);

    return (
        <div data-w-tab="confirmation" className={`confirmation w-tab-pane ${currentStep === currentStepIndex ? 'w--tab-active' : ''}`}>
            <div className="booking-inner-holder">
                <div className="div-block-38">
                    <div className="div-block-39"></div>
                    <div className="booking-title-success-label">Your Appointment <br/>is
                        confirmed!
                    </div>
                </div>
                <div className="appointment-confirmed-background">
                    <div className="appointment-confirmation-provider-holder">
                        <div className="div-block-41">
                            <img style={{ objectFit: 'cover'}} loading="lazy" src={selectedProvider?.photo} alt="Service Provider" className="image"/>
                            <div>
                                <div
                                    className="appointment-confirmation-provider-name">{selectedProvider?.name ?? 'Professional'}
                                </div>
                                <div
                                    className="appointment-confirmation-provider-position">{selectedProvider?.bio ?? 'Service Provider'}
                                </div>
                            </div>
                        </div>
                        <div className="appointment-confirmation-amount-label">{serviceAmount}</div>
                    </div>
                    <div className="appointment-confirmation-entries">
                        <div className="appointment-confirmation-entry-holder">
                            <div className="appointment-confirmation-entry-title">Service
                            </div>
                            <div
                                className="appointment-confirmation-entry-value-label">{selectedService?.service?.title}
                            </div>
                        </div>
                        <div className="appointment-confirmation-entry-holder">
                            <div className="appointment-confirmation-entry-title">Duration
                            </div>
                            <div className="appointment-confirmation-entry-value-label">1 hr
                                15 min
                            </div>
                        </div>
                        <div className="appointment-confirmation-entry-holder">
                            <div className="appointment-confirmation-entry-title">Scheduled
                                Date
                            </div>
                            <div className="appointment-confirmation-entry-value-label">{selectedTimeslotDate ? formatDate() : "-"}
                            </div>
                        </div>
                        <div className="appointment-confirmation-entry-holder">
                            <div className="appointment-confirmation-entry-title">Addons</div>
                            <div
                                className="appointment-confirmation-entry-value-label">{addonsAmount}
                            </div>
                        </div>
                    </div>
                    <div className="appointment-confirmation-add-to-calendar-holder">
                        <div className="appointment-confirmation-calendar-title">Add to
                            Calendar
                        </div>
                        <div
                            className="w-layout-grid appointment-confirmation-calendar-grid">
                            <div id="w-node-_8bef66c0-2dc2-0756-eaa5-83165c507513-03cc0abe"
                                 className="calendar-option-item">
                                <img width="14" height="14" alt="Apple Calendar" src={appleLogo} loading="lazy"/>
                            </div>
                            <div id="w-node-_2e39b010-7533-5ca2-69bf-9b5b4a92f039-03cc0abe"
                                 className="calendar-option-item">
                                <img width="14" height="14" alt="Google Calendar" src={googleLogo} loading="lazy"/>
                            </div>
                            <div id="w-node-_0f17b14a-af9c-99f8-cdaa-301916669970-03cc0abe"
                                 className="calendar-option-item">
                                <img width="14" height="14" alt="twitter" src={twitterLogo} loading="lazy"/>
                            </div>
                        </div>
                    </div>
                    <div className="appointment-confirmation-cost-holder">
                        <div className="div-block-46">
                            <div
                                className="appointment-confirmation-bottom-total-label">Total
                            </div>
                            <div className="tax-included-label">Incl taxes and fees</div>
                        </div>
                        <div className="appointment-confirmation-amount-label">{amount}</div>
                    </div>
                </div>
                <div className="appointment-confirmed-background-map">
                    <img width="67" height="67" alt="Google Maps" src={mapLogo} loading="lazy" className="image-2"/>
                    <div className="div-block-49">
                        <div className="appointment-confirmation-address-holder">
                            <div className="address-name-label">Main Headquarters</div>
                            <div className="address-label">1438 Marshville Road, NYC</div>
                        </div>
                        <a href="/" onClick={(e) => handleGetDirections(e)} className="get-directions-link">Get Directions</a>
                    </div>
                </div>
                <a href="/" onClick={(e) => handlePayNow(e)} className="appointment-confirmed-button w-button">Pay Now</a>
            </div>
        </div>
    );

}

export default Confirmation;
