const ParlorHelper = {

    storeParlor(data) {
        localStorage.setItem('parlor', JSON.stringify(data));
    },

    getParlor() {
        const parlor = localStorage.getItem('parlor');
        if (parlor) {
            return JSON.parse(parlor);
        }
        return null;
    }
}

export default ParlorHelper
