import React, {useEffect} from "react";
import ServiceItem from "./SelectService/ServiceItem";
import NetworkingProvider from "../../../helpers/NetworkingProvider";
import ParlorHelper from "../../../helpers/ParlorHelper";

const SelectService = (props) => {
    const [services, setServices] = React.useState([1,2,3,4,5,6]);
    const { currentStep, currentStepIndex, selectedService, serviceSelected } = props;

    async function fetchAllServices() {
        const payload = { parlorId: ParlorHelper.getParlor()?._id}
        try {
            const dataResponse = await NetworkingProvider.fetchDataFullResponse('services/list-all', 'POST', payload);
            const result = dataResponse.result;

            if (result) {

                console.log(result)
                setServices(result)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchAllServices()
    },[])

    return (
        <div data-w-tab="select-service" className={`select-service w-tab-pane ${currentStep === currentStepIndex ? 'w--tab-active' : ''}`}>
            <div className="booking-inner-holder">
                <div className="booking-title-label">Select A Service</div>
                <div className="select_service_holder">
                    <div className="w-layout-grid select-service-grid" style={{ marginBottom: '250px'}}>
                        {services.map((service, index) => (
                            <div key={index} onClick={() => serviceSelected(service)}>
                                <ServiceItem service={service}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SelectService;
